import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/zap-onboard-api/zap-onboard-api/services-unmanaged/zap-onboard-home-page/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Emplogin1 } from '../../../components/images/support/users/Emplogin1.generated';
import { Emplogin2 } from '../../../components/images/support/users/Emplogin2.generated';
import { Emplogin3 } from '../../../components/images/support/users/Emplogin3.generated';
import { Emplogin4 } from '../../../components/images/support/users/Emplogin4.generated';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "employee-login-️"
    }}>{`Employee Login 🧑‍🍳👨🏾‍💼👷‍♀️`}</h1>
    <p>{`This section shows how employees login to Canyou. `}</p>
    <p>{`The screenshots are shown from desktop but you can login from mobile or tablet as well.`}</p>
    <hr></hr>
    <ol>
      <li parentName="ol">{`Navigate to `}<a parentName="li" {...{
          "href": "https://canyou.com.au"
        }}>{`https://canyou.com.au`}</a>{` and click the `}<strong parentName="li">{`Log in`}</strong>{` button`}</li>
    </ol>

    <Emplogin1 mdxType="Emplogin1" />
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`Enter your email address and click the `}<strong parentName="li">{`Login with Email`}</strong>{` button.`}</li>
    </ol>
    <p>{`This will send an email to your inbox with a single use link to gain access to the platform.`}</p>

    <Emplogin2 mdxType="Emplogin2" />
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`Check your email and then click the `}<strong parentName="li">{`Log in`}</strong>{` button. If you didn't recieve an email check your spam.`}</li>
    </ol>

    <Emplogin3 mdxType="Emplogin3" />
    <ol {...{
      "start": 4
    }}>
      <li parentName="ol">{`Great work you have logged in 👏. Now click the Action Items to get started.`}</li>
    </ol>

    <Emplogin4 mdxType="Emplogin4" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      